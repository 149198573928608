import React from 'react';

import SEO from '../components/seo';
import './stylesheets/404.scss';
import Button from '../components/button';

const NotFoundPage = () => (
  <div
    className="error-container"
    style={{
      paddingTop: '18rem',
      paddingRight: '1rem',
      paddingLeft: '1rem',
      color: 'black!important',
      textAlign: 'center',
    }}
  >
    <SEO title="404: Not found" />
    <p>This page does not exist!</p>
    <Button
      buttonColor="#26333c"
      buttonSize="mid"
      isButton={false}
      buttonUrl="/"
    >
      BACK
    </Button>
    <div className="spacer" />
  </div>
);

export default NotFoundPage;
